<template>
  <div>
    <p class="headline black--text font-weight-bold">Keranjang</p>
    <div v-if="!isLoading">
      <div v-if="listCart.length > 0">
        <v-row class="mt-0">
          <div class="col-md-8 col-lg-8">
            <v-card class="overflow-hidden elevation-1 mb-5" v-for="list in listCart" :key="list.id">
              <!-- <v-card-title>Item Detail</v-card-title> -->
              <v-card-text>
                <!-- <v-divider class="mb-3"></v-divider> -->
                <div class="row">
                  <div class="col-md-6">
                    <p class="d-flex align-center mb-0 font-weight-bold">{{ list.package.name }}</p><br>
                    <p class="d-flex align-center mb-0">
                      <i class="bi bi-person-workspace"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-workspace primary--text mr-2" viewBox="0 0 16 16">
                        <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                        <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                      </svg>
                    <span class="ml-1 text-capitalize">{{ list.package.description | labelFormat }}</span></p><br>
                    <p class="d-flex align-center mb-0">
                      <b-icon icon="image" class="primary--text text-24 mr-2"></b-icon>
                      <span class="ml-1 text-capitalize">{{ list.package.content }}</span></p><br>
                    <p class="d-flex align-center mb-0">
                      <b-icon icon="calendar-3" class="primary--text text-24 mr-2"></b-icon>
                      <span class="ml-1">{{ list.package.duration }}</span></p>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex justify-end mb-6" v-if="!isMobile()">
                      <p class="font-weight-bold mb-0"><strong class="secondary--text">Rp {{ list.package.price | numberFormat }}</strong></p>
                    </div>
                    <p class="d-flex align-center mb-6">
                      <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon>
                      <span class="">Nano</span>
                    </p>
                    <p class="d-flex align-center mb-0">
                      <b-icon icon="person" class="primary--text text-24 mr-2"></b-icon>
                      <span class="">{{ list.package.qty }} Influencer</span>
                    </p>
                    <v-divider v-if="isMobile()" class="mt-3"></v-divider>
                    <v-btn v-if="!isLoadingDelete" @click="openDialogDelete(list.cart.id)" color="error" small class="text-capitalize float-right mt-2"><i class="far fa-trash-alt mr-2"></i> Hapus</v-btn>
                    <v-btn v-else disabled color="error" small class="text-capitalize"><i class="far fa-trash-alt mr-2"></i> Loading...</v-btn>
                    <div class="mt-3" v-if="isMobile()">
                      <p class="font-weight-bold mb-0"><strong class="secondary--text">Rp {{ list.package.price | numberFormat }}</strong></p>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-4">
            <v-card class="elevation-1">
              <v-card-title>Ringkasan Item</v-card-title>
              <v-list two-line>
                <template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title >Total Item</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="subtitle-2">(<span>{{ listCart.length }} </span>Item)</v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">Total Harga</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text><h2>Rp {{totalPrice | numberFormat}}</h2></v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
              <div class="row ml-1 mr-1">
                <div class="col-md-12">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  block
                  @click="goToPayment"
                  >
                  <!-- <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon>  -->
                  <i class="fas fa-credit-card mr-2"></i>
                  Checkout ({{ listCart.length }})
                </v-btn>
                </div>
            </div>
            </v-card>
          </div>
        </v-row>
      </div>
      <!-- KERANJANG KOSONG -->
      <div v-else>
        <div class="row d-flex justify-center mt-5">
          <v-card >
            <v-card-title>Oops! Keranjang Anda Masih Kosong</v-card-title>
            <v-card-actions class="d-flex justify-center mt-5">
              <v-btn @click="goStarted" class="text-capitalize" color="primary">Lihat Paket Sekarang</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
    <!-- LOADING -->
    <div v-else>
      <div class="row">
        <div class="col-md-8">
          <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
        </div>
        <div class="col-md-4">
          <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <v-skeleton-loader
              type="card"
            ></v-skeleton-loader>
        </div>
      </div>
    </div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogDelete"
        persistent
        max-width="350"
      >
        <v-card>
          <div v-if="isLoadingDelete" class="d-flex justify-center pa-10">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <diV v-else-if="isLoadingDeleteSuccess" >
            <div class="d-flex justify-center pa-10 pb-3">
              <i class="far fa-check-circle primary--text" style="font-size:55px"></i>
            </div>
            <v-card-text class="d-flex justify-center">Item berhasil dihapus</v-card-text>
          </diV>
          <div  v-else-if="isLoadingDeleteError">
            <div class="d-flex justify-center pa-10 pb-3">
              <i class="far fa-times-circle error--text" style="font-size:55px"></i>
            </div>
            <v-card-text class="d-flex justify-center">Item gagal dihapus</v-card-text>
          </div>
          <div v-else>
            <div class="d-flex justify-center pt-8">
              <i class="fas fa-exclamation-circle warning--text" style="font-size:55px"></i>
            </div>
            <v-card-title class="text-h5 d-flex justify-center">
              Apakah Anda Yakin?
            </v-card-title>
            <v-card-text>Item yang dihapus tidak dapat dikembalikan</v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="dialogDelete = false"
              >
                Batal
              </v-btn>
              <v-btn
                color="error"
                class="text-capitalize"
                @click="deleteCart"
              >
                Hapus
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  props:['selectedCount'],
  setup() {
    return{
      item:'',
      totalPrice:0,
      listCart:'',
      isLoading:false,
      isLoadingDelete:false,
      isLoadingDeleteSuccess:false,
      isLoadingDeleteError:false,
      dialogDelete:false,
      itemDelete:''
    }
  },
  mounted(){
    this.loadCart()
  },
  methods:{
    loadCart(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getCart', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.listCart = response.data.data
            for(let a = 0; a < this.listCart.length; a++){
              if(this.listCart[a].cart.type === 'custom'){
                this.listCart[a].package.description = response.data.data[a].package.type
                this.listCart[a].package.type = response.data.data[a].package.level
                this.listCart[a].package.content = response.data.data[a].package.format
              }
            }
            for(let a=0; a < this.listCart.length; a++){
              this.totalPrice += parseInt(this.listCart[a].package.price)
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
          // setTimeout(() => { 
          //   this.isLoading = false; 
          //   this.alertType='error';
          //   this.alertMessage='Email / Password Salah';
          //   this.alertStatus=true;
          //   this.resetForm();
          // }, 2000);
      });
    },
    openDialogDelete(val){
      this.dialogDelete= true;
      this.itemDelete = val;
    },
    deleteCart(){
      this.isLoadingDelete = true;
      let token = localStorage.getItem('token')
      axios.delete(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/removeCart/'+this.itemDelete, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.loadCart()
            setTimeout(() => { 
              this.isLoadingDeleteSuccess=true;
              this.isLoadingDelete = false
              setTimeout(() => { 
                this.dialogDelete= false;
                location.reload();
              }, 1000);
            }, 500);
          }
        }).catch(error => {
          console.log(error)
          this.dialogDelete= false;
          this.isLoadingDeleteError=true;
      });
      // axios.delete(process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/removeCart', {
      //   headers: {
      //     'Authorization': 'Bearer '+ token
      //   },
      //   data: {
      //     id: source
      //   }
      // });
    },
    goStarted(){
      this.$router.push({name:'campaign'}); 
    },
    goToPayment(){
      localStorage.setItem('checkout', '')
      localStorage.setItem('checkout', JSON.stringify(this.listCart))
      localStorage.setItem('checkoutNow', false)
      this.$router.push({name:'campaign-payment'});
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  },
})
</script>