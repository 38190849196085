<template>
  <v-row>
    <v-col cols="12">
      <cart-item></cart-item>
    </v-col>
  </v-row>
</template>

<script>
import CartItem from './components/Cart.vue'

export default {
  components: {
    CartItem,
  },
  setup() {
    return {}
  },
}
</script>
